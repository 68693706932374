import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://d34994b4043a637e026b3543f54488de@o958077.ingest.us.sentry.io/4508253679255552",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});